import { NavPage } from 'layouts/NavPage';
import { DashboardPage } from 'pages/ResidentPortal/DashboardPage';
import { EnvironmentMonitoringPage } from 'pages/ResidentPortal/EnvironmentMonitoringPage';
import { MyAccountPage } from 'pages/ResidentPortal/MyAccountPage';
import { MyEnergyPage } from 'pages/ResidentPortal/MyEnergyPage';
import { UltraWebAppPage } from 'pages/ResidentPortal/UltraWebAppPage';
import { Navigate, RouteObject } from 'react-router';

export const residentUrl = '/resident';

export const residentPortalRoutes: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="dashboard" />,
  },
  {
    path: 'dashboard',
    element: <DashboardPage />,
  },
  {
    path: 'my-home',
    element: <Navigate to="my-energy" />,
  },
  {
    path: 'my-home/my-energy',
    element: <MyEnergyPage />,
  },
  {
    path: 'my-home/environmental-monitoring',
    element: <EnvironmentMonitoringPage />,
  },
  {
    path: 'ultra-web-app',
    element: <UltraWebAppPage />,
  },
  {
    path: 'my-account',
    element: <MyAccountPage />,
  },
];

export const residentPortalNavPages: NavPage[] = [
  {
    key: 'Dashboard',
    path: 'dashboard',
    pageDisplayName: 'Dashboard',
    isRoot: true,
  },
  {
    key: 'MyHome',
    path: 'my-home',
    pageDisplayName: 'My Home',
    isRoot: true,
  },
  {
    key: 'MyEnergy',
    path: 'my-home/my-energy',
    pageDisplayName: 'My Energy',
    isRoot: false,
  },
  {
    key: 'EnvironmentalMonitoring',
    path: 'my-home/environmental-monitoring',
    pageDisplayName: 'Environmental Monitoring',
    isRoot: false,
  },
  {
    key: 'UltraWebApp',
    path: 'ultra-web-app',
    pageDisplayName: 'Ultra Web App',
    isRoot: true,
  },
  { key: 'MyAccount', path: 'my-account', pageDisplayName: 'My Account', isRoot: true },
];
