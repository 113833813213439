import { Spinner } from '@mgh-app/component-library';
import { useAuth } from 'hooks/useAuth';
import { PermissionGroup } from 'models/user';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

export type ProtectedRouteProps = {
  permissionGroups?: PermissionGroup[];
} & PropsWithChildren;

export const ProtectedRoute = ({ children, permissionGroups }: ProtectedRouteProps) => {
  const { user, isLoading } = useAuth();

  if (isLoading) return <Spinner />;

  if (!user) return <Navigate to="/unauthorized" replace />;

  return permissionGroups?.some((permissionGroup) => user?.groups.includes(permissionGroup)) ? (
    <>{children}</>
  ) : (
    <Navigate to="/" replace />
  );
};
