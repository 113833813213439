import { RoomData } from 'models/globalHomeModels';
import { useCallback, useMemo } from 'react';
import { useLazyGetAllRoomsQuery, useGetThingsMutation } from 'services/homeApi';

export const useAllRoomsAirQualitySensorData = () => {
  const [getAllRooms] = useLazyGetAllRoomsQuery();
  const [getThings] = useGetThingsMutation();

  const getData = useCallback(async () => {
    const roomsData: RoomData[] = [];
    const roomsResponse = await getAllRooms(undefined, true).unwrap();

    if (!roomsResponse) {
      return;
    }

    for await (const room of roomsResponse.data) {
      const things = await getThings({ roomId: room.id }).unwrap();
      const airQualitySensorsThing = things.data.find(
        (t) => t.name === 'Sensors' && t.identifier === 'AIR_QUALITY_SENSORS',
      );
      roomsData.push({
        roomId: room.id,
        roomName: room.nick_name,
        ...(airQualitySensorsThing ? { airQualitySensorsId: airQualitySensorsThing?.id } : {}),
      });
    }
    return roomsData;
  }, [getAllRooms, getThings]);

  return useMemo(() => [getData], [getData]);
};
