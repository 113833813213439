import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { authFetchBaseQuery, greenmindUrl } from './apiConfig';
import { Portfolio } from 'models';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const baseEndpointUrl = greenmindUrl;
const apiPrefix = '/portfolios';

export interface AddPortfolioRequest {
  name: string;
  description: string;
  account_type: string;
}

export interface PortfolioApiError {
  error: string;
  status: string;
}

export interface EditPortfolioRequest extends AddPortfolioRequest {
  id: string
}

export interface DeletePortfolioRequest {
  id: string
}

export const portfolioApi = createApi({
  reducerPath: 'portfolioApi',
  baseQuery: authFetchBaseQuery({
    baseUrl: baseEndpointUrl,
  }),
  endpoints: (builder) => ({
    getPortfolios: builder.query<Portfolio[], void>({
      query: () => ({ url: `${apiPrefix}` }),
      transformResponse: (response: Portfolio[]): Portfolio[] =>
        response.sort((a, b) => a.name.localeCompare(b.name))
    }),
    addPortfolio: builder.mutation<Portfolio, AddPortfolioRequest>({
      query: (request) => ({
        url: `${apiPrefix}`,
        method: 'POST',
        body: request,
      }),
    }),
    editPortfolio: builder.mutation<Portfolio, EditPortfolioRequest>({
      query: (request) => ({
        url: `${apiPrefix}/${request.id}`,
        method: 'PUT',
        body: request
      }),
    }),
    deletePortfolio: builder.mutation<unknown, DeletePortfolioRequest>({
      query: (request) => ({
        url: `${apiPrefix}/${request.id}`,
        method: 'DELETE',
        body: request
      }),
    })
  }),
});

const selectPortfolioResult = portfolioApi.endpoints.getPortfolios.select();

export const selectAllPortfolios = createSelector(
    selectPortfolioResult,
    (roomsResult) => roomsResult.data ?? []
);

export const selectPortfolioById = createSelector(
    selectAllPortfolios,
    (state: RootState, portfolioId: string) => portfolioId,
    (portfolios: Portfolio[], portfolioId: string) =>
        portfolios.find((portfolio) => portfolio.id === portfolioId)
);

export const {
  useGetPortfoliosQuery,
  useAddPortfolioMutation,
  useEditPortfolioMutation,
  useDeletePortfolioMutation
} = portfolioApi;
