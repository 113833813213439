import { DevelopmentListItem } from 'components/DevelopmentListItem';
import { useAppNavigation } from 'hooks/useAppNavigation';
import type { Development } from 'models';

export interface DevelopmentListProps {
  developments: Development[];
}

export const DevelopmentList = ({ developments }: DevelopmentListProps): JSX.Element => {
  const { navigateHomes } = useAppNavigation();
  const sortedDevelopments = [...developments].sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div>
      {sortedDevelopments.map((development) => (
        <DevelopmentListItem
          key={development.developmentId}
          developmentId={development.developmentId}
          name={development.name}
          city={development.locale.city}
          country={development.country}
          onClick={() => navigateHomes(development.developmentId)}
        />
      ))}
    </div>
  );
};
