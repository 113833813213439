import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { SensorsRangeValues } from 'models';
import { authFetchBaseQuery, homeApiUrl } from './apiConfig';

export interface HomeApiResponseBase<TData = unknown> {
  data: TData;
  statusCode: number;
  status: string;
  message: string | string[];
}

export type HomeApiErrorResponse<TError> = HomeApiResponseBase<TError>;

export type UnitData = {
  id: string;
  name: string;
  unit_type: string;
  property_id: string;
  building_id: string;
  physical_location: string;
};

export type GetUnitInfoRequest = {
  unitId: string;
};
export type GetUnitInfoResponse = HomeApiResponseBase<UnitData>;

type RoomData = {
  id: string;
  nick_name: string;
};

export type GetAllRoomsResponse = HomeApiResponseBase<RoomData[]> & {
  error: boolean;
};

export interface ThingData {
  id: string;
  name: string;
  identifier: string;
}

export type GetThingsRequest = {
  roomId: string;
};

export type GetThingsResponse = HomeApiResponseBase<ThingData[]>;

export type GetRangesResponse = HomeApiResponseBase<SensorsRangeValues>;

export interface SensorData {
  label: string;
  rh?: number;
  co2?: number;
  dew?: number;
  lux?: number;
  voc?: number;
  pm1?: number;
  pm25?: number;
  pm4?: number;
  pm10?: number;
  air_pressure?: number;
  sound_pressure?: number;
  temp?: number;
}

export type GetSensorsRequest = {
  thingId: string;
};

export type GetSensorsResponse = HomeApiResponseBase<SensorData[]>;

export interface EnergyData {
  days: number[];
  months: number[];
  name: string;
  type: string;
  status: string;
}

export type GetAllEnergyResponse = HomeApiResponseBase<EnergyData>;

export interface EnergyCircuitData {
  [id: string]: EnergyData;
}

export type GetDetailedEnergyUsageResponse = HomeApiResponseBase<EnergyCircuitData>;

export const homeApi = createApi({
  reducerPath: 'homeApi',
  baseQuery: authFetchBaseQuery({
    baseUrl: `${homeApiUrl}`,
  }),
  endpoints: (builder) => ({
    getUnitInfo: builder.query<GetUnitInfoResponse, GetUnitInfoRequest>({
      query: ({ unitId }) => ({ url: `/properties/units/${unitId}` }),
    }),
    getAllRooms: builder.query<GetAllRoomsResponse, void>({
      query: () => ({ url: 'rooms' }),
    }),
    getThings: builder.mutation<GetThingsResponse, GetThingsRequest>({
      query: ({ roomId }) => ({ url: 'things/list', method: 'POST', body: { roomId } }),
    }),
    getRanges: builder.query<GetRangesResponse, void>({
      query: () => ({ url: 'things/sensors/ranges' }),
    }),
    getSensors: builder.query<GetSensorsResponse, GetSensorsRequest>({
      query: ({ thingId }) => ({ url: `things/sensors/get/${thingId}` }),
    }),
    getAllEnergyUsage: builder.query<GetAllEnergyResponse, void>({
      query: () => ({ url: 'things/energy-usage/get/all' }),
    }),
    getDetailedEnergyUsage: builder.query<GetDetailedEnergyUsageResponse, void>({
      query: () => ({ url: 'things/energy-usage/detailed' }),
    }),
  }),
});

export const {
  useGetUnitInfoQuery,
  useLazyGetUnitInfoQuery,
  useGetAllRoomsQuery,
  useLazyGetAllRoomsQuery,
  useGetThingsMutation,
  useGetRangesQuery,
  useLazyGetRangesQuery,
  useGetSensorsQuery,
  useLazyGetSensorsQuery,
  useGetAllEnergyUsageQuery,
  useLazyGetAllEnergyUsageQuery,
  useGetDetailedEnergyUsageQuery,
  useLazyGetDetailedEnergyUsageQuery,
} = homeApi;
