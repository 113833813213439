import { EnvironmentSensorType } from '@mgh-app/component-library';
import { DateTime } from 'luxon';
import { EnvMonitoringAggregatesAccumulator, HistoricalEntry, SensorAggregates } from 'models';
import { getTokensFromLocalStorage, setTokensInLocalStorage } from 'services/apiConfig';
import { ErrorResponse, ApiError } from 'services/request';
import { Auth } from 'aws-amplify';

export const getPreviousThirtyDaysUTCDateRange = (
  excludeToday = true,
): { startUtcIsoDate: string; endUtcIsoDate: string } => {
  const dateDiff = excludeToday ? 31 : 30;
  const today = DateTime.utc();
  const startUtcIsoDate = today.minus({ days: dateDiff }).toISODate();
  const endUtcIsoDate = excludeToday ? today.minus({ day: 1 }).toISODate() : today.toISODate();

  return { startUtcIsoDate, endUtcIsoDate };
};

export const historialEnvironmentalDataApi = async <TResponse, TError = unknown>(
  url: string,
  config: RequestInit = {},
): Promise<TResponse | never | undefined> => {
  const fetchFunc = async () =>
    fetch(url, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getTokensFromLocalStorage()?.token}`,
      },
      ...config,
    });

  const response = await fetchFunc();
  try {
    if (response.status == 200) {
      const data: TResponse = await response.json();
      return data;
    } else if (response.status === 401) {
      const currentSession = await Auth.currentSession();
      const accessToken = currentSession.getAccessToken().getJwtToken();
      const refreshToken = currentSession.getRefreshToken().getToken();
      setTokensInLocalStorage(accessToken, refreshToken);

      const resp = await fetchFunc();
      if (resp.status == 200) {
        const data: TResponse = await resp.json();
        return data;
      }
    }
  } catch {
    if (response.status >= 400) {
      const error: ErrorResponse<TError> = await response.json();
      throw new ApiError(response.statusText, error);
    }
  }
};

export const addAccumulators = (
  a: EnvMonitoringAggregatesAccumulator,
  b: EnvMonitoringAggregatesAccumulator,
): EnvMonitoringAggregatesAccumulator => ({
  runningTotal: b.runningTotal + a.runningCount,
  runningCount: b.runningCount + a.runningCount,
  runningMax: Math.max(b.runningMax, a.runningMax),
  runningMin: Math.min(b.runningMin, a.runningMin),
});

export const accumulateSingleSensorTypeFromHistoricalEntry = (
  entry: HistoricalEntry,
  type: EnvironmentSensorType,
  existing?: EnvMonitoringAggregatesAccumulator,
): EnvMonitoringAggregatesAccumulator => {
  const currentEntryAccumulator = createAccumulatorFromHistoricalEntry(entry, type);
  // In the case where a accumulator for the date does not exist already, create and return a new accumulator from the entry.
  if (!existing) {
    return currentEntryAccumulator;
  }
  // In the case where there is an accumulator for the date, accumulate together the existing and the current entry accumulators.
  const accumulated = addAccumulators(currentEntryAccumulator, existing);
  return accumulated;
};

export const createAccumulatorFromHistoricalEntry = (
  entry: HistoricalEntry,
  type: EnvironmentSensorType,
): EnvMonitoringAggregatesAccumulator => {
  const fullEntry = new Map<string, SensorAggregates>(Object.entries(entry));

  // Extract only the required sensor type's data from the current historical entry
  const currentSensorTypeAggregates = fullEntry.get(type);
  if (!currentSensorTypeAggregates) {
    throw Error('Historical entry conversion attempted on unsupported environmental sensor type');
  }

  // Create and return the new accumulator from the relevant sensor data

  return createAccumulatorFromSensorAggregatesOrDefault(currentSensorTypeAggregates);
};

const createAccumulatorFromSensorAggregatesOrDefault = (
  sensorAggregates?: SensorAggregates,
): EnvMonitoringAggregatesAccumulator => {
  if (!sensorAggregates) {
    return {
      runningTotal: 0,
      runningCount: 0,
      runningMax: 0,
      runningMin: 0,
    };
  }
  const accumulator: EnvMonitoringAggregatesAccumulator = {
    runningTotal: sensorAggregates.avg,
    runningMax: sensorAggregates.max,
    runningMin: sensorAggregates.min,
    // TODO: Change to use count when available from backend source.
    runningCount: 1,
  };
  return accumulator;
};

export const sortIsoDateKeyedTupleArray = function <TValue>(
  unsortedSortData: (readonly [string, TValue])[],
) {
  return unsortedSortData.sort(([a], [b]) => {
    const aDate = DateTime.fromISO(a, { zone: 'utc' }).toMillis();
    const bDate = DateTime.fromISO(b, { zone: 'utc' }).toMillis();
    return aDate - bDate;
  });
};
