import { SessionProvider } from 'auth/SessionProvider';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes';
import { Authenticator } from './components/Authenticator';

const App = () => {
  return (
    <Authenticator>
      <SessionProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </SessionProvider>
    </Authenticator>
  );
};

export default App;
