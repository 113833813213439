import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { developmentsApi } from 'services/homeManagementApi/developmentsApi';
import { homeApi } from 'services/homeApi';
import { imagesManagementApi } from 'services/homeManagementApi/imagesManagementApi';
import { openMeteoApi } from 'services/externalApi/openMeteoApi';
import { unitsManagementApi } from 'services/homeManagementApi/unitsManagementApi';
import { signInApi, usersApi } from 'services/usersApi';
import { portfolioApi } from './services/portfolioApi';

export const store = configureStore({
  reducer: {
    [developmentsApi.reducerPath]: developmentsApi.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [imagesManagementApi.reducerPath]: imagesManagementApi.reducer,
    [openMeteoApi.reducerPath]: openMeteoApi.reducer,
    [portfolioApi.reducerPath]: portfolioApi.reducer,
    [signInApi.reducerPath]: signInApi.reducer,
    [unitsManagementApi.reducerPath]: unitsManagementApi.reducer,
    [portfolioApi.reducerPath]: portfolioApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      developmentsApi.middleware,
      homeApi.middleware,
      imagesManagementApi.middleware,
      openMeteoApi.middleware,
      portfolioApi.middleware,
      signInApi.middleware,
      unitsManagementApi.middleware,
      portfolioApi.middleware,
      usersApi.middleware,
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;
