import { Alert, CircularProgress } from '@mui/material';
import {
  display,
  emptySpaceStyle,
  gap,
  gridTemplateColumns,
  gridTemplateRows,
  margin,
  padding,
  textAlign,
  twCls,
  width,
} from 'style';

import { DevelopmentList, PageTitle } from 'components';
import { useGetAllDevelopmentsQuery } from 'services/homeManagementApi/developmentsApi';

export const DevelopmentsPage = () => {
  const { data: developments, isError, error } = useGetAllDevelopmentsQuery();

  const developmentsListControl =
    developments && developments.length > 0 ? (
      <DevelopmentList developments={developments} />
    ) : (
      <div className={emptySpaceStyle}>No developments to display.</div>
    );

  const loadingErrorControl = isError ? (
    <div>
      <Alert severity="error" className={twCls(margin('mt-6'), margin('m-auto'), width('w-4/5'))}>
        <span>{'status' in error ? (error.data as any)?.message : error.message}</span>
      </Alert>
    </div>
  ) : (
    <div className={twCls(textAlign('text-center'), padding('pt-5'))}>
      <CircularProgress />
    </div>
  );

  return (
    <div
      className={twCls(
        display('grid'),
        gridTemplateColumns('grid-cols-1'),
        gridTemplateRows('grid-rows-min-fc'),
        gap('gap-12'),
      )}
    >
      <PageTitle>Developments</PageTitle>
      {developments ? developmentsListControl : loadingErrorControl}
    </div>
  );
};
