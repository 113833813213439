import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Home } from 'models';
import { HomeUser } from 'models/homeUser';
import { useEffect, useState } from 'react';
import { useInviteUserMutation } from 'services/homeManagementApi/developmentsApi';
import { dialogContentStyle, dialogTitleStyle, justifyContent, margin, twCls, width } from 'style';
import { validateEmail } from 'utils/emailUtils';
import { getErrorMessage } from 'utils/errorUtils';

export interface InviteHomeUserDialogProps {
  open: boolean;
  onClose: () => void;
  home: Home;
  onUserInvited: (user: HomeUser) => void;
}

const DialogState = {
  Init: 'Init',
  Busy: 'Busy',
  Complete: 'Complete',
  Error: 'Error',
} as const;
type DialogState = typeof DialogState[keyof typeof DialogState];

export const InviteHomeUserDialog = ({
  open,
  onClose,
  home,
  onUserInvited,
}: InviteHomeUserDialogProps): JSX.Element => {
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.Init);
  const [error, setError] = useState<string>();
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [emailAddressHelperText, setEmailAddressHelperText] = useState<string>('');
  const [inviteUserMutation] = useInviteUserMutation();

  useEffect(() => {
    //Reset the states on dialog open
    if (open) {
      setDialogState(DialogState.Init);
      setEmailAddress('');
      setEmailAddressHelperText('Email address is empty');
      setError(undefined);
    }
  }, [open]);

  //Used for inviting user API
  useEffect(() => {
    if (!emailAddress) return;
    if (!home) return;
    if (dialogState != DialogState.Busy) return;

    const inviteUser = async () => {
      try {
        const homeUser = await inviteUserMutation({
          developmentId: home.developmentId,
          homeId: home.homeId,
          email: emailAddress,
          resendInvite: false,
        }).unwrap();
        onUserInvited(homeUser);
        setDialogState(DialogState.Complete);
      } catch (error) {
        setDialogState(DialogState.Error);
        setError(getErrorMessage(error));
      }
    };

    inviteUser();
  }, [dialogState, emailAddress, home, inviteUserMutation, onUserInvited]);

  const handleChangeEmailAddress = (event: { target: { value: string } }) => {
    const emailValue = event.target.value;

    //Check for white-spaced string
    if (!emailValue.trim()) {
      setEmailAddress('');
      setEmailAddressHelperText('Email address is empty');
      return;
    }

    //Validate email
    if (!validateEmail(emailValue)) {
      setEmailAddress('');
      setEmailAddressHelperText('Email address is not valid');
      return;
    }

    //All good
    setEmailAddress(emailValue);
    setEmailAddressHelperText('');
  };

  const handleInviteClick = async () => {
    setDialogState(DialogState.Busy);
  };

  return (
    <Dialog open={open}>
      <DialogTitle className={dialogTitleStyle} sx={{ minWidth: 350 }}>
        Invite User
      </DialogTitle>
      <DialogContent className={dialogContentStyle}>
        <p>
          Invite user to <strong>{home.name}</strong>
        </p>
        <br />
        <TextField
          className={twCls(width('w-full'))}
          required
          disabled={dialogState == DialogState.Busy || dialogState == DialogState.Complete}
          id="inviteUserEmail"
          label="Email Address"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          error={!emailAddress}
          onChange={handleChangeEmailAddress}
          helperText={emailAddressHelperText}
          autoComplete="new-password" //Disable auto complete
        />

        {dialogState == DialogState.Error && error && (
          <Alert severity="error" className={twCls(margin('mt-3'))}>
            <span dangerouslySetInnerHTML={{ __html: error }} />
          </Alert>
        )}
        {dialogState == DialogState.Complete && (
          <Alert severity="success" className={twCls(margin('mt-3'))}>
            User has been invited to home.
          </Alert>
        )}
      </DialogContent>
      <DialogActions className={twCls(justifyContent('justify-between'))}>
        <div>{dialogState == DialogState.Busy && <CircularProgress />}</div>
        <div>
          <Button
            disabled={
              !emailAddress ||
              dialogState == DialogState.Busy ||
              dialogState == DialogState.Complete
            }
            onClick={handleInviteClick}
          >
            Invite
          </Button>
          <Button disabled={dialogState == DialogState.Busy} onClick={onClose}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
