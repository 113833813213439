import { Spinner } from '@mgh-app/component-library';
import { AirQualitySensorsContainer } from 'components/AirQualitySensorsContainer';
import { useAllRoomsAirQualitySensorData } from 'hooks/useAllRoomsAirQualitySensorData';
import { RoomAirQualitySensorsData, RoomData } from 'models/globalHomeModels';
import { useCallback, useEffect, useState } from 'react';
import { useLazyGetSensorsQuery } from 'services/homeApi';
import { display, flexDirection, justifyContent, padding, twCls } from 'style';
import { SensorsUtils } from '../../../utils';

const airQualityRefreshIntervalMs = 30000; // 30 secs

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EnvironmentalMonitoringPanelProps {}

export const EnvironmentalMonitoringPanel =
  ({}: EnvironmentalMonitoringPanelProps): JSX.Element => {
    const [airQualitySensorsData, setAirQualitySensorsData] = useState<
      RoomAirQualitySensorsData[] | null
    >(null);
    const [getAllRoomsAirQualitySensorData] = useAllRoomsAirQualitySensorData();
    const [getSensors] = useLazyGetSensorsQuery();

    const extractAirQualSensorDataFromRoomData = useCallback(
      async (roomData: RoomData[] | undefined) => {
        if (!roomData || !roomData?.length) {
          return;
        }

        const sensorsData: RoomAirQualitySensorsData[] = [];
        for (const room of roomData) {
          if (room.airQualitySensorsId) {
            const sensors = await getSensors({ thingId: room.airQualitySensorsId }, true).unwrap();
            const airQualitySensors = SensorsUtils.convert(sensors.data);
            const roomAirQualitySensors: RoomAirQualitySensorsData[] = airQualitySensors.map(
              (s) => ({
                type: s.type,
                value: s.value,
                label: s.label,
                roomId: room.roomId,
                roomName: room.roomName,
              }),
            );
            sensorsData.push(...roomAirQualitySensors);
          }
        }

        setAirQualitySensorsData(sensorsData);
      },
      [getSensors],
    );

    useEffect(() => {
      (async () => {
        const roomData = await getAllRoomsAirQualitySensorData();
        await extractAirQualSensorDataFromRoomData(roomData);

        const airQualitySensorsDataIntervalCall = setInterval(async () => {
          await extractAirQualSensorDataFromRoomData(roomData);
        }, airQualityRefreshIntervalMs);

        return () => {
          // clean up
          clearInterval(airQualitySensorsDataIntervalCall);
        };
      })();
    }, [extractAirQualSensorDataFromRoomData, getAllRoomsAirQualitySensorData]);

    return (
      <div
        className={twCls(
          display('flex'),
          flexDirection('flex-col'),
          justifyContent('justify-start'),
          padding('py-6'),
        )}
      >
        {airQualitySensorsData ? (
          <AirQualitySensorsContainer sensorsData={airQualitySensorsData} />
        ) : (
          <Spinner />
        )}
      </div>
    );
  };
