import { Alert, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  display,
  emptySpaceStyle,
  gap,
  gridTemplateColumns,
  gridTemplateRows,
  margin,
  padding,
  textAlign,
  twCls,
  width,
} from 'style';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { PageTitle } from 'components';
import { HomeList } from 'components/HomeList';
import {
  useGetDevelopmentQuery,
  useGetHomesForDevelopmentQuery,
} from 'services/homeManagementApi/developmentsApi';

export const HomesPage = () => {
  const { developmentId } = useParams();

  const { data: development, error: developmentsError } = useGetDevelopmentQuery(
    developmentId ? { developmentId } : skipToken,
  );
  const {
    data: homes,
    refetch: refetchHomes,
    error: homesError,
  } = useGetHomesForDevelopmentQuery(developmentId ? { developmentId } : skipToken, {
    pollingInterval: 30000,
  });

  const homesListControl =
    homes != null && homes.length > 0 ? (
      <div>
        <HomeList homes={homes} updateHomeCallback={refetchHomes} />
      </div>
    ) : (
      <div className={emptySpaceStyle}>No homes to display.</div>
    );

  const error = developmentsError || homesError;

  const loadingErrorControl = error ? (
    <div>
      <Alert severity="error" className={twCls(margin('mt-6'), margin('m-auto'), width('w-4/5'))}>
        <span
          dangerouslySetInnerHTML={{
            __html: 'status' in error ? (error.data as any) : error.message,
          }}
        />
      </Alert>
    </div>
  ) : (
    <div className={twCls(textAlign('text-center'), padding('pt-5'))}>
      <CircularProgress />
    </div>
  );

  const title = development ? `Homes for ${development.name}` : 'Homes';

  return (
    <div
      className={twCls(
        display('grid'),
        gridTemplateColumns('grid-cols-1'),
        gridTemplateRows('grid-rows-min-fc'),
        gap('gap-12'),
      )}
    >
      <PageTitle>{title}</PageTitle>
      {homes ? homesListControl : loadingErrorControl}
    </div>
  );
};
