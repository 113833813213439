import { useState } from 'react';
import type { Home } from 'models';
import { HomeListItem } from 'components/HomeListItem';
import { HomeConfigUploadDialog } from 'components/HomeConfigUploadDialog';
import { ProcessingDialog, ProcessingDialogState } from 'components/ProcessingDialog';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { display } from 'style';
import { Toast, ToastKind, ToastProps } from 'components/Toast';
import { getErrorMessage } from 'utils/errorUtils';
import { ManageHomeUsersDialog } from '../ManageHomeUsersDialog/ManageHomeUsersDialog';
import { useAppNavigation } from 'hooks/useAppNavigation';
import {
  useLazyGetConfigImageQuery,
  useLazyGetHomeQuery,
  useUpdateShadowMutation,
} from 'services/homeManagementApi/developmentsApi';

export interface HomeListProps {
  homes: Home[];
  updateHomeCallback: (home: Home) => void;
}

const VisibleDialog = {
  None: 'None',
  Upload: 'Upload',
  Confirm: 'Confirm',
  Process: 'Process',
  Users: 'Users',
} as const;
type VisibleDialog = typeof VisibleDialog[keyof typeof VisibleDialog];

export const HomeList = ({ homes, updateHomeCallback }: HomeListProps): JSX.Element => {
  const [toastProps, setToastProps] = useState<ToastProps>();

  const sortedHomes = [...homes].sort((a, b) => a.name.localeCompare(b.name));
  const [selectedHome, setSelectedHome] = useState<Home | null>(null);
  const [visibleDialog, setVisibleDialog] = useState<VisibleDialog>(VisibleDialog.None);
  const { navigateHomeDetails } = useAppNavigation();
  const [updateShadow] = useUpdateShadowMutation();
  const [getConfigImage] = useLazyGetConfigImageQuery();
  const [getHome] = useLazyGetHomeQuery();

  //Used for Processing and Upload dialog
  const handleCloseDialog = () => {
    setVisibleDialog(VisibleDialog.None);
  };

  //Processing dialog
  const [messageProcessDialog, setMessageProcessingDialog] = useState<string>();
  const [stateProcessingDialog, setStateProcessDialog] = useState<ProcessingDialogState>(
    ProcessingDialogState.Busy,
  );

  //Confirmation dialog
  const handleConfirmationButtonClick = async (accepted: boolean) => {
    setVisibleDialog(VisibleDialog.None);

    if (!selectedHome) return;

    //The confirmation was rejected, do nothing
    if (!accepted) return;

    setVisibleDialog(VisibleDialog.Process);
    setStateProcessDialog(ProcessingDialogState.Busy);
    try {
      //Call the web service to update shadows
      const updatedHome = await updateShadow({
        developmentId: selectedHome.developmentId,
        homeId: selectedHome.homeId,
      }).unwrap();

      //Refresh the UI
      updateHomeCallback(updatedHome);

      //Show SUCCESS message
      setMessageProcessingDialog('Configuration synchronised successfully');
      setStateProcessDialog(ProcessingDialogState.Success);
    } catch (error) {
      setMessageProcessingDialog(getErrorMessage(error));
      setStateProcessDialog(ProcessingDialogState.Error);
    }
  };

  const handleDownloadToken = async (home: Home) => {
    try {
      //Call the web service to get config for home
      const homeImageConfig = await getConfigImage({
        developmentId: home.developmentId,
        homeId: home.homeId,
      }).unwrap();

      //Setup the anchor to simulate a download click
      const homeImageConfigJson = JSON.stringify(homeImageConfig);
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(homeImageConfigJson);
      const downloadControl = document.getElementById('downloadControl');
      if (downloadControl) {
        downloadControl.setAttribute('href', dataStr);
        downloadControl.setAttribute('download', 'config.json');
        downloadControl.click();
      }
    } catch (error) {
      const message = getErrorMessage(error);

      //Create the HTML message (JSX Element) to be displayed
      const messageComponent = () => (
        <>
          <p>Failed to download token for home {home.name}.</p>
          <br />
          <p>{message}</p>
        </>
      );

      //Set the toast props.  This will create a new toast to display error.
      setToastProps({
        message: messageComponent,
        toastKind: ToastKind.Error,
        toastId: `download-token-${home.homeId}}`, //Use ToastId to prevent duplicates
      });
    }
  };

  const handleDownloadConfig = async (home: Home) => {
    try {
      //Call the web service to get home
      const loadedHome = await getHome({
        developmentId: home.developmentId,
        homeId: home.homeId,
      }).unwrap();

      if (!loadedHome.configInfo || !loadedHome.configInfo.data)
        throw new Error('Previous config does not exist.');

      //Setup the anchor to simulate a download click
      const dataStr =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
        encodeURIComponent(loadedHome.configInfo.data);
      const downloadControl = document.getElementById('downloadControl');
      if (downloadControl) {
        downloadControl.setAttribute('href', dataStr);
        downloadControl.setAttribute(
          'download',
          `${loadedHome.name} [${loadedHome.homeId}] Configuration.xlsx`,
        );
        downloadControl.click();
      }
    } catch (error) {
      const message = getErrorMessage(error);

      //Create the HTML message (JSX Element) to be displayed
      const messageComponent = () => (
        <>
          <p>Failed to download config for home {home.name}.</p>
          <br />
          <p>{message}</p>
        </>
      );

      //Set the toast props.  This will create a new toast to display error.
      setToastProps({
        message: messageComponent,
        toastKind: ToastKind.Error,
        toastId: `download-config-${home.homeId}}`, //Use ToastId to prevent duplicates
      });
    }
  };

  /** This anchor control is to simulate a download click so we can download
   * the json returned from the API as a file named config.json.
   */
  // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
  const downloadControl = <a id="downloadControl" className={display('!hidden')}></a>;

  return (
    <div>
      {downloadControl}

      {toastProps && (
        <Toast
          message={toastProps.message}
          toastKind={toastProps.toastKind}
          toastId={toastProps.toastId}
        />
      )}

      {sortedHomes.map((home) => (
        <HomeListItem
          key={home.homeId}
          home={home}
          onClick={() => navigateHomeDetails(home.developmentId, home.homeId)}
          onClickUpload={() => {
            setSelectedHome(home);
            setVisibleDialog(VisibleDialog.Upload);
          }}
          onClickShadows={() => {
            setSelectedHome(home);
            setVisibleDialog(VisibleDialog.Confirm);
          }}
          onClickDownloadToken={() => {
            handleDownloadToken(home);
          }}
          onClickManageUsers={() => {
            setSelectedHome(home);
            setVisibleDialog(VisibleDialog.Users);
          }}
          onClickDownloadConfig={() => {
            handleDownloadConfig(home);
          }}
        />
      ))}

      {selectedHome && (
        <>
          <HomeConfigUploadDialog
            open={visibleDialog == VisibleDialog.Upload}
            onClose={handleCloseDialog}
            home={selectedHome}
            updateHomeCallback={updateHomeCallback}
          />

          <ProcessingDialog
            open={visibleDialog == VisibleDialog.Process}
            onClose={handleCloseDialog}
            title={'Synchronise Configuration'}
            description={`Synchronising configuration for <strong>${selectedHome.name}</strong>`}
            state={stateProcessingDialog}
            message={messageProcessDialog}
          />

          <ConfirmationDialog
            open={visibleDialog == VisibleDialog.Confirm}
            title={'Synchronise Configuration'}
            description={`Synchronise the configuration for <strong>${selectedHome.name}</strong>?`}
            onButtonClick={handleConfirmationButtonClick}
            acceptText={'Yes'}
            rejectText={'No'}
          />

          <ManageHomeUsersDialog
            open={visibleDialog == VisibleDialog.Users}
            home={selectedHome}
            onClose={handleCloseDialog}
          />
        </>
      )}
    </div>
  );
};
