import { adminPortalNavPages, adminPortalRoutes, adminUrl } from 'adminPortalRoutes';
import { AppGateway } from 'auth/AppGateway';
import { ProtectedRoute } from 'auth/ProtectedRoute';
import { PermissionGroup } from 'models/user';
import { UnauthorizedPage } from 'pages/UnauthorizedPage';
import {
  portfolioManagerNavPages,
  portfolioManagerRoutes,
  portfolioManagerUrl,
} from 'portfolioManagerRoutes';
import { useRoutes } from 'react-router-dom';
import { residentPortalNavPages, residentPortalRoutes, residentUrl } from 'residentPortalRoutes';
import { MainLayout } from './layouts/MainLayout';

/*
  /
    /residents/{unitId} - resident portal
    /admin - admin portal
    /portfolio/{portofolioId} - portfolio manager
*/

const residentsOnly: PermissionGroup[] = ['residents'];
const adminOnly: PermissionGroup[] = ['mgh-administrators'];
const portfolioManagerOnly: PermissionGroup[] = ['portfolio-managers'];

const Router = () => {
  const routes = useRoutes([
    {
      children: [
        {
          path: '/',
          element: (
            <AppGateway
              redirects={[
                { permissionGroup: 'residents', to: residentUrl },
                { permissionGroup: 'mgh-administrators', to: adminUrl },
                { permissionGroup: 'portfolio-managers', to: portfolioManagerUrl },
              ]}
            />
          ),
        },
        {
          path: residentUrl,
          element: (
            <ProtectedRoute permissionGroups={residentsOnly}>
              <MainLayout appName="User Log Book" navPages={residentPortalNavPages} />
            </ProtectedRoute>
          ),
          children: residentPortalRoutes,
        },
        {
          path: adminUrl,
          element: (
            <ProtectedRoute permissionGroups={adminOnly}>
              <MainLayout appName="Admin Portal" navPages={adminPortalNavPages} />
            </ProtectedRoute>
          ),
          children: adminPortalRoutes,
        },
        {
          path: portfolioManagerUrl,
          element: (
            <ProtectedRoute permissionGroups={portfolioManagerOnly}>
              <MainLayout appName="Portfolio Manager" navPages={portfolioManagerNavPages} />
            </ProtectedRoute>
          ),
          children: portfolioManagerRoutes,
        },
        {
          path: '/unauthorized',
          element: <MainLayout appName="" navPages={[]} />,
          children: [
            {
              path: '',
              element: <UnauthorizedPage />,
            },
          ],
        },
      ],
    },
  ]);

  return routes;
};

export default Router;
