import { Button } from '@mgh-app/component-library';
import { FormControlLabel, Radio } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import { Dialog } from 'components/Dialog';
import { DropdownMenu } from 'components/DropdownMenu';
import { Sidebar } from 'components/Sidebar';
import { useAppNavigation } from 'hooks/useAppNavigation';
import { useAuth } from 'hooks/useAuth';
import { FC, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  useGetConsentQuery,
  useSaveConsentMutation,
} from 'services/homeManagementApi/unitsManagementApi';
import {
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  display,
  flexDirection,
  fontSize,
  fontWeight,
  gap,
  height,
  justifyContent,
  listStylePosition,
  listStyleType,
  margin,
  maxHeight,
  maxWidth,
  minWidth,
  overflow,
  padding,
  textColor,
  textDecoration,
  twCls,
  width,
} from 'style';
import PropcodeLogo from '../assets/propcode-logo.webp';
import { ReactComponent as SmallLogoIcon } from '../assets/smallLogo.svg';
import { ReactComponent as LogoIcon } from '../logo.svg';
import { NavPage } from './NavPage';

export interface MainLayoutContextProps {
  showDataConsentModal: (show: boolean) => void;
}

export const MainLayoutContext = createContext<MainLayoutContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showDataConsentModal: () => {},
});

export type MainLayoutProps = {
  appName: string;
  navPages: NavPage[];
};

export const MainLayout: FC<MainLayoutProps> = ({ appName, navPages }) => {
  const { user, signOut } = useAuth();
  const { navigateHome } = useAppNavigation();
  const { data: consent, isFetching: isFetchingConsent } = useGetConsentQuery({
    unitId: user?.unitId || '',
  });
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [selectedConsent, setSelectedConsent] = useState<boolean | undefined>();
  const [saveConsent] = useSaveConsentMutation();

  const showDataConsentModal = useCallback((show: boolean) => setShowConsentModal(show), []);

  const mainLayoutContext = useMemo(() => ({ showDataConsentModal }), [showDataConsentModal]);

  const isPropcode =
    user?.groups.includes('portfolio-managers') || user?.groups.includes('mgh-administrators');

  useEffect(() => {
    setShowConsentModal((consent && !consent.userId) || false);
    setSelectedConsent(consent?.consented);
  }, [consent, isFetchingConsent]);

  const onCloseConsentModal = () => {
    if (selectedConsent !== undefined) {
      setShowConsentModal(false);
    }
  };

  const onSubmitConsent = async () => {
    if (user?.unitId && selectedConsent !== undefined) {
      await saveConsent({ unitId: user.unitId, consented: selectedConsent });
      onCloseConsentModal();
    }
  };

  return (
    // TODO: better way for global min width?
    <div style={{ minWidth: '393px' }}>
      <div
        className={twCls(
          backgroundColor('bg-grey-100'),
          height('h-20', 'lg:h-24'),
          display('flex'),
          alignItems('items-center'),
          justifyContent('justify-between'),
        )}
      >
        <div className={twCls(display('flex', 'lg:hidden'), width('w-20'), padding('pl-3'))}>
          <DropdownMenu navPages={navPages} />
        </div>
        <div
          className={twCls(
            padding('pl-5'),
            display('flex', 'lg:hidden'),
            justifyContent('justify-center'),
          )}
        >
          {isPropcode ? (
            <img
              className={twCls(maxWidth('max-w-small-logo-width'))}
              src={PropcodeLogo}
              alt="Propcode logo"
            />
          ) : (
            <SmallLogoIcon />
          )}
        </div>
        <div
          className={twCls(
            padding('pl-5'),
            display('hidden', 'lg:flex'),
            justifyContent('justify-center'),
          )}
        >
          {isPropcode ? (
            <img className={twCls(maxWidth('max-w-xs'))} src={PropcodeLogo} alt="Propcode logo" />
          ) : (
            <LogoIcon />
          )}
        </div>
        <div className={twCls(display('flex'), padding('pr-2', 'lg:pr-10'))}>
          <div
            className={twCls(
              display('hidden', 'lg:flex'),
              fontSize('text-2xl'),
              fontWeight('font-light'),
              padding('px-8'),
            )}
          >
            Welcome, {user?.name}
          </div>
          <div className={twCls(width('w-20', 'lg:w-fit'))}>
            <button
              type="button"
              onClick={() => {
                signOut();
                navigateHome();
              }}
              className={twCls(
                padding('px-2', 'lg:px-6', 'py-1'),
                borderRadius('rounded'),
                backgroundColor('bg-white'),
                textColor('text-blue-100'),
                fontWeight('font-medium'),
              )}
            >
              Log Out
            </button>
          </div>
        </div>
      </div>

      <div className={twCls(display('flex'), flexDirection('flex-col', 'lg:flex-row'))}>
        <div className={twCls(display('hidden', 'lg:flex'), minWidth('lg:min-w-fit'))}>
          <Sidebar appName={appName} navPages={navPages} />
        </div>

        <div
          className={twCls(
            display('flex', 'lg:hidden'),
            padding('pl-4', 'pt-4'),
            fontSize('text-xl'),
            width('w-full'),
            justifyContent('justify-center'),
          )}
        >
          {appName}
        </div>

        <div
          className={twCls(
            width('w-full'),
            padding('px-4', 'pb-4'),
            margin('mt-6'),
            borderWidth('border-l'),
            borderColor('border-grey-300'),
          )}
        >
          <MainLayoutContext.Provider value={mainLayoutContext}>
            <Outlet />
          </MainLayoutContext.Provider>
          <Dialog
            open={showConsentModal}
            handleClose={onCloseConsentModal}
            actionButtons={<Button onClick={onSubmitConsent}>Submit</Button>}
            contentClassName={twCls(overflow('overflow-y-auto'), maxHeight('max-h-modal-height'))}
            footer={
              <div className={twCls(display('flex'), justifyContent('justify-center'))}>
                <a
                  href="https://www.myglobalhome.co/privacy-policy/"
                  className={twCls(textDecoration('underline'))}
                >
                  Privacy Policy
                </a>
              </div>
            }
          >
            <div className={twCls(display('grid'), gap('gap-9'))}>
              <h1 className={twCls(fontSize('text-4xl'), fontWeight('font-extralight'))}>
                Data Sharing Permission
              </h1>
              <p>
                We store historical home data to:
                <ul className={twCls(listStyleType('list-disc'), listStylePosition('list-inside'))}>
                  <li>Provide insights via historical trends</li>
                  <li>Track potential hardware problems in homes</li>
                  <li>
                    Help us understand how our software is being used and how we could further
                    improve it
                  </li>
                </ul>
              </p>
              <p>
                By accepting you will be sharing:
                <ul className={twCls(listStyleType('list-disc'), listStylePosition('list-inside'))}>
                  <li>Energy usage data</li>
                  <li>Sensor and climate data</li>
                  <li>Lighting usage data</li>
                  <li>Shading (blinds/curtains) usage data </li>
                  <li>Activity & performance data (buttons, panel compute statistics)</li>
                </ul>
              </p>
              <p>
                If you accept now you can change this at any time by going to the settings menu and
                changing the &lsquo;Share my home data&rsquo; setting.
              </p>
              <RadioGroup
                onChange={(_, value) => setSelectedConsent(value === 'consented')}
                value={selectedConsent ? 'consented' : 'non'}
              >
                <FormControlLabel
                  value="consented"
                  control={<Radio />}
                  label="I give consent for my data to be used"
                />
                <FormControlLabel
                  value="non"
                  control={<Radio />}
                  label="I do not give consent for my data to be used"
                />
              </RadioGroup>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
