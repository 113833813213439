import { ApiError, ErrorResponse } from '../request';

const baseEndpointUrl = 'https://epc.opendatacommunities.org';

export interface EPCApiDataEntry {
  address: string;
  address1: string;
  address2: string;
  address3: string;
  'building-reference-number': string;
  'built-form': string;
  'co2-emiss-curr-per-floor-area': string;
  'co2-emissions-current': string;
  'co2-emissions-potential': string;
  constituency: string;
  'constituency-label': string;
  'construction-age-band': string;
  county: string;
  'current-energy-efficiency': string;
  'current-energy-rating': string;
  'energy-consumption-current': string;
  'energy-consumption-potential': string;
  'energy-tariff': string;
  'environment-impact-current': string;
  'environment-impact-potential': string;
  'extension-count': string;
  'fixed-lighting-outlets-count': string;
  'flat-storey-count': string;
  'flat-top-storey': string;
  'floor-description': string;
  'floor-energy-eff': string;
  'floor-env-eff': string;
  'floor-height': string;
  'floor-level': string;
  'glazed-area': string;
  'glazed-type': string;
  'heat-loss-corridor': string;
  'heating-cost-current': string;
  'heating-cost-potential': string;
  'hot-water-cost-current': string;
  'hot-water-cost-potential': string;
  'hot-water-energy-eff': string;
  'hot-water-env-eff': string;
  'hotwater-description': string;
  'inspection-date': string;
  'lighting-cost-current': string;
  'lighting-cost-potential': string;
  'lighting-description': string;
  'lighting-energy-eff': string;
  'lighting-env-eff': string;
  'lmk-key': string;
  'local-authority': string;
  'local-authority-label': string;
  'lodgement-date': string;
  'lodgement-datetime': string;
  'low-energy-fixed-light-count': string;
  'low-energy-lighting': string;
  'main-fuel': string;
  'main-heating-controls': string;
  'mainheat-description': string;
  'mainheat-energy-eff': string;
  'mainheat-env-eff': string;
  'mainheatc-energy-eff': string;
  'mainheatc-env-eff': string;
  'mainheatcont-description': string;
  'mains-gas-flag': string;
  'mechanical-ventilation': string;
  'multi-glaze-proportion': string;
  'number-habitable-rooms': string;
  'number-heated-rooms': string;
  'number-open-fireplaces': string;
  'photo-supply': string;
  postcode: string;
  posttown: string;
  'potential-energy-efficiency': string;
  'potential-energy-rating': string;
  'property-type': string;
  'roof-description': string;
  'roof-energy-eff': string;
  'roof-env-eff': string;
  'secondheat-description': string;
  'sheating-energy-eff': string;
  'sheating-env-eff': string;
  'solar-water-heating-flag': string;
  tenure: string;
  'total-floor-area': string;
  'transaction-type': string;
  'unheated-corridor-length': string;
  uprn: string;
  'uprn-source': string;
  'walls-description': string;
  'walls-energy-eff': string;
  'walls-env-eff': string;
  'wind-turbine-count': string;
  'windows-description': string;
  'windows-energy-eff': string;
  'windows-env-eff': string;
}

export interface EPCApiData {
  'column-names': string[];
  rows: EPCApiDataEntry[];
  currentEnergyRating: string;
  potentialEnergyRating: string;
  currentEnergyEfficiency: number;
  potentialEnergyEfficiency: number;
}

const EPCApi = async <TResponse, TError = unknown>(
  url: string,
  config: RequestInit = {},
): Promise<TResponse | never | undefined> => {
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      Authorization: `Basic ${window.__RUNTIME_CONFIG__.EPC_API_KEY}`,
    },
    ...config,
  });
  try {
    if (response.status == 200) {
      const data: TResponse = await response.json();
      return data;
    }
  } catch {
    if (response.status >= 400) {
      const error: ErrorResponse<TError> = await response.json();
      throw new ApiError(response.statusText, error);
    }
  }
};

export const EPCByAddressAndOptionalPostCode = async (
  addressConcatenatedWithPlusSymbol: string,
  noSpacesPostCode?: string | null,
) =>
  EPCApi<EPCApiData>(
    noSpacesPostCode
      ? `${baseEndpointUrl}/api/v1/domestic/search?postcode=${noSpacesPostCode}&address=${addressConcatenatedWithPlusSymbol}`
      : `${baseEndpointUrl}/api/v1/domestic/search?address=${addressConcatenatedWithPlusSymbol}`,
  );

// Example URL, for postcode: "SW8 5HT", and address string "Flat 3 45 Roland Street":
//  "https://epc.opendatacommunities.org/api/v1/domestic/search?postcode=SW85HT&address=Flat+3+45+Roland+Street"
