import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { Auth } from 'aws-amplify';

const getBaseUrl = () => {
  switch (window.__RUNTIME_CONFIG__.REACT_APP_MGH_ENV) {
    case 'prod':
      return 'https://api.ultra.myglobalhome.co';
    case 'uat':
      return 'https://api.uat.mgh.beintrepid.co.uk';
    case 'qa':
      return 'https://api.qa.mgh.beintrepid.co.uk';
    default:
      return 'https://api.test.mgh.beintrepid.co.uk';
  }
};

export const homeApiUrl = getBaseUrl();

const getBaseManagementUrl = () => {
  switch (window.__RUNTIME_CONFIG__.REACT_APP_MGH_ENV) {
    case 'prod':
      return 'https://home-management-api.ultra.myglobalhome.co';
    case 'uat':
      return 'https://home-management-api.uat.mgh.beintrepid.co.uk';
    case 'qa':
      return 'https://home-management-api.qa.mgh.beintrepid.co.uk';
    default:
      return (
        process.env.REACT_APP_MGH_MANAGEMENT_API_OVERRIDE ||
        'https://home-management-api.test.mgh.beintrepid.co.uk'
      );
  }
};

export const baseManagementUrl = getBaseManagementUrl();

const getUltraWebAppUrl = () => {
  switch (window.__RUNTIME_CONFIG__.REACT_APP_MGH_ENV) {
    case 'prod':
      return 'https://app.ultra.myglobalhome.co';
    case 'uat':
      return 'https://app.uat.mgh.beintrepid.co.uk';
    case 'qa':
      return 'https://app.qa.mgh.beintrepid.co.uk';
    default:
      return (
        process.env.REACT_APP_MGH_ULTRA_WEBAPP_URL_OVERRIDE ||
        'https://app.test.mgh.beintrepid.co.uk'
      );
  }
};

export const ultraWebAppUrl = getUltraWebAppUrl();

const getGreenmindUrl = () => {
  switch (window.__RUNTIME_CONFIG__.REACT_APP_MGH_ENV) {
    case 'prod':
      return 'https://greenmind-api.ultra.myglobalhome.co';
    case 'uat':
      return 'https://greenmind-api.uat.mgh.beintrepid.co.uk';
    case 'qa':
      return 'https://greenmind-api.qa.mgh.beintrepid.co.uk';
    default:
      return (
        process.env.REACT_APP_MGH_GREENMIND_API_URL_OVERRIDE ||
        'https://greenmind-api.test.mgh.beintrepid.co.uk'
      );
  }
};

export const greenmindUrl = getGreenmindUrl();

const TOKEN_KEY = 'mgh-token';
const REFRESH_TOKEN_KEY = 'mgh-refresh-token';

export const setTokensInLocalStorage = (token: string, refreshToken: string) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const clearTokensInLocalStorage = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const getTokensFromLocalStorage = (): { token: string; refreshToken: string } => {
  const token = localStorage.getItem(TOKEN_KEY) || '';
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) || '';

  return { token, refreshToken };
};

export const authFetchBaseQuery = (args: Parameters<typeof fetchBaseQuery>[0]) => {
  const fetchBaseQueryFn = fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${getTokensFromLocalStorage()?.token}`);
      return headers;
    },
    ...args,
  });

  return async (...args: Parameters<typeof fetchBaseQueryFn>) => {
    const result = await fetchBaseQueryFn(...args);
    if (result.error && result.error.status === 401) {
      try {
        const currentSession = await Auth.currentSession();

        const accessToken = currentSession.getAccessToken().getJwtToken();
        const refreshToken = currentSession.getRefreshToken().getToken();

        setTokensInLocalStorage(accessToken, refreshToken);

        return fetchBaseQueryFn(...args);
      } catch (err) {
        console.log('authFetchBaseQuery refresh ERROR', err);
        clearTokensInLocalStorage();
        // location.reload();
      }
    }
    return result;
  };
};

export enum CircuitType {
  Heating = 'heating',
  Lighting = 'lighting',
  Sockets = 'socket',
}

export const countryCode = window.__RUNTIME_CONFIG__.COUNTRY_CODE;

export const downloadUrlConfigTemplate = window.__RUNTIME_CONFIG__.DOWNLOAD_URL_CONFIG_TEMPLATE;
export const downloadUrlBootstrapUltra = window.__RUNTIME_CONFIG__.DOWNLOAD_URL_BOOTSTRAP_ULTRA;
export const downloadUrlPrepareHome = window.__RUNTIME_CONFIG__.DOWNLOAD_URL_PREPARE_HOME;
