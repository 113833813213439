import {
  backgroundColor,
  borderColor,
  borders,
  fontSize,
  fontStyle,
  fontWeight,
  height,
  margin,
  padding,
  textAlign,
  textColor,
  twCls,
  verticalAlign,
  width,
} from 'style';

export const emptySpaceStyle = twCls(
  backgroundColor('bg-white'),
  textColor('text-black'),
  textAlign('text-center'),
);

export const contentStyle = twCls(height('h-4'), backgroundColor('bg-grey-50'));

export const dataCardStyle = twCls(
  margin('m-auto'),
  width('w-4/5'),
  borders('border-b'),
  borderColor('border-b-grey-200'),
  textColor('text-black'),
  padding('pb-1', 'pt-1'),
);

export const dataCardFooterStyle = twCls(
  margin('m-auto'),
  width('w-4/5'),
  textColor('text-grey-400'),
  padding('pb-1', 'pt-1'),
  fontStyle('italic'),
  fontSize('text-sm'),
);

/** For simple 2 column table to show labels (left) and values (right) */
export const propertyTableStyle = twCls(margin('ml-4'), margin('mb-2'));
export const propertyTableStyleHeader = twCls(
  textColor('text-blue-900'),
  fontWeight('font-bold'),
  fontSize('text-lg'),
  margin('mb-3'),
);
export const propertyTableLabelStyle = twCls(fontWeight('font-bold'));
export const propertyTableValueStyle = twCls(padding('pl-6'));

/** Style the MUI dialog */
export const dialogTitleStyle = twCls(backgroundColor('bg-blue-900'), textColor('text-white'));
export const dialogContentStyle = twCls(margin('mt-6'));

/** Style of home status */
export const homeStatusIconStyle = twCls(verticalAlign('align-top'), margin('mr-1'));

/** Style for downloads */
export const downloadLinkStyle = twCls(
  textColor('text-blue-900'),
  fontWeight('font-bold'),
  fontSize('text-lg'),
);
export const downloadDescriptionStyle = twCls(margin('ml-5'));
export const downloadTextStyle = twCls(margin('ml-5'), fontStyle('!italic'));
export const downloadSpacerStyle = twCls(margin('mt-2'));
export const downloadDateVersionStyle = twCls(margin('ml-5'));
