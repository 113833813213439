import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region:
      window.__RUNTIME_CONFIG__.REACT_APP_COGNITO_REGION || process.env.REACT_APP_COGNITO_REGION,
    userPoolId:
      window.__RUNTIME_CONFIG__.REACT_APP_USER_POOL_ID || process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId:
      window.__RUNTIME_CONFIG__.REACT_APP_USER_POOL_WEB_CLIENT_ID ||
      process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
});
