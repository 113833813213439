import { useAuthenticator } from '@aws-amplify/ui-react';
import { CognitoUserAttribute, CognitoUserSession } from 'amazon-cognito-identity-js';
import { User } from 'models/user';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { setTokensInLocalStorage } from 'services/apiConfig';

const tokenGroupsAttr = 'cognito:groups';

export interface AuthContextProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
  token?: string;
  refreshToken?: string;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => void;
}

const signIn = async () => {
  console.log('empty sign in');
};
const signOut = () => {
  console.log('logout');
};

type UserAttribute = 'sub' | 'email_verified' | 'name' | 'custom:unit_id' | 'email';

const getAttr = (attrName: UserAttribute, attributes?: CognitoUserAttribute[]) =>
  attributes?.find((attr) => attr.Name === attrName)?.Value || '';

const defaultAuthContext: AuthContextProps = {
  isAuthenticated: false,
  isLoading: false,
  signIn,
  signOut,
};

export const AuthContext = React.createContext<AuthContextProps>(defaultAuthContext);

export const SessionProvider = ({ children }: PropsWithChildren) => {
  const [session, setSession] = useState<CognitoUserSession>();
  const [userAttribs, setUserAttribs] = useState<CognitoUserAttribute[]>();
  const { user, authStatus, signOut } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);

  useEffect(() => {
    user?.getSession((err: unknown, session: CognitoUserSession) => {
      if (err) {
        console.error(err);
        return;
      }

      setSession(session);
      setTokensInLocalStorage(
        session?.getAccessToken()?.getJwtToken(),
        session?.getRefreshToken()?.getToken(),
      );
    });

    user?.getUserAttributes((err, attributes) => {
      if (err) {
        console.error(err);
      }

      setUserAttribs(attributes);
    });
  }, [user]);

  const cognitoAccessToken = session?.getAccessToken();
  const token = cognitoAccessToken?.getJwtToken();
  const refreshToken = session?.getRefreshToken()?.getToken();

  const value: AuthContextProps = useMemo(
    () => ({
      user: {
        avatar: '',
        email: getAttr('email', userAttribs),
        localNamespace: '',
        name: getAttr('name', userAttribs),
        status: 0,
        unitId: getAttr('custom:unit_id', userAttribs),
        groups: cognitoAccessToken?.decodePayload()[tokenGroupsAttr] ?? [],
      },
      isAuthenticated: authStatus === 'authenticated',
      isLoading: authStatus === 'configuring' || !user || !userAttribs,
      signIn,
      signOut,
      token,
      refreshToken,
    }),
    [authStatus, cognitoAccessToken, refreshToken, signOut, token, user, userAttribs],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
