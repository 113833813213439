import { FC, PropsWithChildren } from 'react';
import { Authenticator as AwsAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents';
import { ReactComponent as LogoIcon } from '../../logo.svg';
import PropcodeLogo from '../../assets/propcode-logo.webp';
import {
  alignItems,
  display,
  flexDirection,
  fontSize,
  gap,
  gridTemplateColumns,
  gridTemplateRows,
  justifyContent,
  margin,
  maxWidth,
  textAlign,
  twCls,
} from 'style';

import './../../amplifyConfig';
import '@aws-amplify/ui-react/styles.css';
import './styles.scss';

const authComponents: DefaultComponents = {
  Header: () => (
    <div
      className={twCls(
        display('grid'),
        gridTemplateColumns('grid-cols-1'),
        gridTemplateRows('grid-rows-2'),
        gap('gap-2'),
      )}
    >
      <h1 className={twCls(margin('mt-2'), textAlign('text-center'), fontSize('text-2xl'))}>
        Login
      </h1>
    </div>
  ),
};

const Logos: FC = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  return authStatus !== 'authenticated' ? (
    <div
      className={twCls(
        display('flex'),
        flexDirection('flex-row'),
        gap('gap-8'),
        alignItems('items-center'),
        justifyContent('justify-center'),
      )}
    >
      <img className={twCls(maxWidth('max-w-xs'))} src={PropcodeLogo} alt="Propcode logo" />
      <LogoIcon />
    </div>
  ) : null;
};

export const Authenticator: FC<PropsWithChildren> = ({ children }) => (
  <AwsAuthenticator.Provider>
    <>
      <Logos />
      <AwsAuthenticator
        components={authComponents}
        hideSignUp
        formFields={{
          signIn: {
            username: {
              label: 'Email Address',
            },
          },
        }}
      >
        {children}
      </AwsAuthenticator>
    </>
  </AwsAuthenticator.Provider>
);
