import { NavPage } from 'layouts/NavPage';
import { DevelopmentsPage } from 'pages/AdminPortal/DevelopmentsPage';
import { DownloadsPage } from 'pages/AdminPortal/DownloadsPage';
import { HomeDetailsPage } from 'pages/AdminPortal/HomeDetailsPage';
import { HomesPage } from 'pages/AdminPortal/HomesPage';
import { Navigate, RouteObject } from 'react-router';

export const adminUrl = '/admin';

export const adminPortalRoutes: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="developments" />,
  },
  {
    path: 'developments',
    element: <DevelopmentsPage />,
  },
  {
    path: 'developments/:developmentId/homes',
    element: <HomesPage />,
  },
  {
    path: 'developments/:developmentId/homes/:homeId',
    element: <HomeDetailsPage />,
  },
  {
    path: 'downloads',
    element: <DownloadsPage />,
  },
];

export const adminPortalNavPages: NavPage[] = [
  {
    key: 'Developments',
    path: 'developments',
    pageDisplayName: 'Developments',
    isRoot: true,
  },
  {
    key: 'Downloads',
    path: 'downloads',
    pageDisplayName: 'Downloads',
    isRoot: true,
  },
];
