import { FC, useEffect, useState } from 'react';
import {
  PortfolioApiError,
  selectPortfolioById,
  useAddPortfolioMutation,
  useEditPortfolioMutation,
  useGetPortfoliosQuery,
} from 'services/portfolioApi';
import { Button, TextField } from '@mgh-app/component-library';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';

export const AddEditPortfolioPage: FC = () => {
  const { refetch } = useGetPortfoliosQuery();
  const { portfolioId = '' } = useParams();
  const portfolio = useAppSelector((state) => selectPortfolioById(state, portfolioId));

  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [accountType, setAccountType] = useState<string>('');
  const [addEdit, setAddEdit] = useState<'Add' | 'Edit'>('Add');
  const [message, setMessage] = useState<string>('');
  const [addPortfolio] = useAddPortfolioMutation();
  const [editPortfolio] = useEditPortfolioMutation();

  useEffect(() => {
    if (portfolio) {
      setId(portfolio.id);
      setName(portfolio.name);
      setDescription(portfolio.description);
      setAccountType(portfolio.account_type);

      setAddEdit('Edit');
    }
  }, [portfolio]);

  const AddPortfolio = async () => {
    setMessage('Please wait...');

    try {
      const response = await addPortfolio({
        name: name,
        description: description,
        account_type: accountType,
      })
        .unwrap();

      setAddEdit('Edit');
      setMessage('Added new portfolio with id: ' + response.id);

      refetch();
    } catch (error) {
      setMessage('Could not save portfolio: ' + (error as PortfolioApiError).error);
    }
  };

  const EditPortfolio = async () => {
    setMessage('Please wait...');

    try {
      const response = await editPortfolio({
        id: id,
        name: name,
        description: description,
        account_type: accountType,
      })
        .unwrap();

      setMessage('Edited portfolio with id: ' + response.id);

      refetch();
    } catch (error) {
      setMessage('Could not save portfolio: ' + (error as PortfolioApiError).error);
    }
  };

  return (
    <div>
      <h2>{addEdit} Portfolio</h2>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField label={'Portfolio Name'} value={name}
                   onChange={(event) => setName(event.target.value)} />

        <TextField label='Description' value={description}
                   onChange={(event) => setDescription(event.target.value)} />

        <TextField label='Account Type' value={accountType}
                   onChange={(event) => setAccountType(event.target.value)} />

        <Button onClick={() => (addEdit == 'Edit') ? EditPortfolio() : AddPortfolio()}>
          {addEdit} Portfolio
        </Button>

        <p>{message}</p>
      </div>
    </div>
  );
};