import { Spinner } from '@mgh-app/component-library';
import { useAuth } from 'hooks/useAuth';
import { PermissionGroup } from 'models/user';
import { FC } from 'react';
import { Navigate } from 'react-router';

export type AppRedirect = {
  permissionGroup: PermissionGroup;
  to: string;
};

export type AppGateway = {
  redirects: AppRedirect[];
};

export const AppGateway: FC<AppGateway> = ({ redirects }) => {
  const { user, isLoading } = useAuth();

  if (isLoading || !user) return <Spinner />;

  const { groups } = user;

  const currentRedirect = redirects.find((redirect) => groups.includes(redirect.permissionGroup));

  return currentRedirect ? <Navigate to={currentRedirect.to} /> : <Navigate to="/not-found" />;
};
