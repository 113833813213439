import {
  // ConstructionInformationPanel,
  EnvironmentalMonitoringPanel,
  // InsuranceAndWarrantyPanel,
  MyEnergyPanel,
  // UserGuidesPanel,
} from 'components/dashboardPanels';
import { DocumentPanel } from 'components/dashboardPanels/DocumentPanel';
import { Dropdown } from 'components/Dropdown';
import { Header } from 'components/Header/Header';
import { useAuth } from 'hooks/useAuth';
import { FileCategory } from 'models/document';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { residentUrl } from 'residentPortalRoutes';
import { useLazyGetUnitInfoQuery, UnitData } from 'services/homeApi';
import { useGetDocumentsQuery } from 'services/homeManagementApi/unitsManagementApi';
import {
  borderColor,
  borderRadius,
  borderWidth,
  display,
  fontSize,
  fontWeight,
  justifyContent,
  margin,
  padding,
  textColor,
  twCls,
} from 'style';

const DocumentSectionMapping: Record<FileCategory, string> = {
  [FileCategory.ArchitecturalDrawing]: 'Architectural Drawings',
  [FileCategory.ConstructionInformation]: 'Construction Information',
  [FileCategory.Insurance]: 'Insurance Providers',
  [FileCategory.PersonalInformation]: 'Personal Information',
  [FileCategory.Security]: 'Security Information',
  [FileCategory.UnitDetails]: 'Unit Details & Construction Information',
  [FileCategory.UserGuide]: 'User Guides & Product Warranties',
  [FileCategory.UtilitiesInformation]: 'Utilities Information',
  [FileCategory.Warranty]: 'Warranties',
};

export interface DashboardItemProps {
  title: string;
  moreInformationLink?: string;
  children?: ReactElement;
}

export const DashboardItem = ({
  title,
  moreInformationLink,
  children,
}: DashboardItemProps): JSX.Element => {
  return (
    <div
      className={twCls(
        padding('p-5'),
        margin('mt-4'),
        borderWidth('border'),
        borderRadius('rounded-lg'),
        borderColor('border-grey-400'),
      )}
    >
      <div className={twCls(fontSize('text-3xl', 'lg:text-4xl'), fontWeight('font-light'))}>
        {title}
      </div>
      {children}
      <div
        className={twCls(
          fontWeight('font-medium'),
          textColor('text-blue-100'),
          display('flex'),
          justifyContent('justify-end'),
          margin('mt-8', 'lg:mt-0'),
        )}
      >
        {moreInformationLink && <Link to={moreInformationLink}>More information</Link>}
      </div>
    </div>
  );
};

export const DashboardPage = (): JSX.Element => {
  const { user } = useAuth();

  const [unitInfo, setUnitInfo] = useState<UnitData | null>(null);
  const [unitLocation, setUnitLocation] = useState<string[] | null>(null);
  const [rawUnitLocation, setRawUnitLocation] = useState<string[] | null>(null);
  const { data: documents } = useGetDocumentsQuery({ unitId: user?.unitId || '' });
  const [getUnitInfo] = useLazyGetUnitInfoQuery();

  const getUnitData = useCallback(async () => {
    if (user) {
      const unitInfo = await getUnitInfo({ unitId: user.unitId }, true).unwrap();
      if (unitInfo?.data) {
        setUnitInfo(unitInfo.data);

        if (unitInfo.data.physical_location) {
          const locationParts = unitInfo.data.physical_location.split(',');
          if (locationParts.length === 3) {
            const address1 = `${locationParts[0]}, ${locationParts[1]}`;
            const address2 = `${locationParts[2]}`;
            setUnitLocation([address1, address2]);
          } else {
            setUnitLocation([unitInfo.data.physical_location]);
          }
          setRawUnitLocation(locationParts.map((part) => part.trim().replaceAll(' ', '+')));
        }
      }
    }
  }, [getUnitInfo, user]);

  useEffect(() => {
    (async () => {
      await getUnitData();
    })();
  }, [getUnitData]);

  return (
    <>
      <div className={twCls(padding('pb-2'))}>
        {unitInfo && unitLocation && (
          <Header
            title={unitInfo.name}
            subTitle1={unitLocation[0]}
            subTitle2={unitLocation.length > 1 ? unitLocation[1] : undefined}
          />
        )}
      </div>

      <div
        className={twCls(display('flex'), justifyContent('justify-end'), margin('mt-2', 'lg:mt-0'))}
      >
        <Dropdown items={['Today']} defaultSelection="Today" />
      </div>

      <DashboardItem title="My Energy" moreInformationLink={`${residentUrl}/my-home/my-energy`}>
        {/**As in the precedent of the header above, there are a lot of uncomfortable assumptions made here to try to parse out postcode and address data, a more reliable is needed. **/}

        {/**The value for unitAddress is assumed to be comprised of the first portions of a multi-term address, or the full single term if there is only one.**/}
        {/**The value for unitPostCode is assumed, based on the precedent above in the header, that the postcode is the last but one term of a multi-term location**/}
        <MyEnergyPanel
          unitAddress={
            rawUnitLocation && rawUnitLocation.length >= 3 ? rawUnitLocation.slice(0, 2) : undefined
          }
          unitPostCode={
            rawUnitLocation && rawUnitLocation.length >= 3
              ? rawUnitLocation[rawUnitLocation.length - 2]?.replaceAll('+', '')
              : undefined
          }
        />
      </DashboardItem>

      <DashboardItem
        title="Environmental Monitoring"
        moreInformationLink={`${residentUrl}/my-home/environmental-monitoring`}
      >
        <EnvironmentalMonitoringPanel />
      </DashboardItem>

      {documents &&
        Object.entries(documents?.grouped)
          .filter((entry) => Object.values(FileCategory).includes(entry[0] as FileCategory))
          .map((entry) => (
            <DocumentPanel
              key={entry[0]}
              documents={entry[1]}
              summary={DocumentSectionMapping[entry[0] as FileCategory]}
            />
          ))}
    </>
  );
};
