import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Home } from 'models';
import { useEffect, useState } from 'react';
import { useUploadConfigurationMutation } from 'services/homeManagementApi/developmentsApi';
import { dialogContentStyle, dialogTitleStyle, justifyContent, margin, twCls } from 'style';

export interface HomeConfigUploadDialogProps {
  open: boolean;
  onClose: () => void;
  home: Home;
  updateHomeCallback: (home: Home) => void;
}

const acceptedFileExt = '.xlsx';

const DialogState = {
  Init: 'Init',
  Ready: 'Ready',
  Uploading: 'Uploading',
  Error: 'Error',
  Complete: 'Complete',
} as const;
type DialogState = typeof DialogState[keyof typeof DialogState];

export const HomeConfigUploadDialog = ({
  open,
  onClose,
  home,
  updateHomeCallback,
}: HomeConfigUploadDialogProps): JSX.Element => {
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.Init);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [error, setError] = useState<string>();
  const [performUpgrade, setPerformUpgrade] = useState<boolean>(true);
  const [uploadConfiguration] = useUploadConfigurationMutation();

  //Kick off the upload of the config
  useEffect(() => {
    if (!selectedFile) return;

    if (dialogState != DialogState.Uploading) return;

    const uploadConfig = async () => {
      try {
        const fileBase64 = await fileToBase64(selectedFile);
        const updatedHome = await uploadConfiguration({
          developmentId: home.developmentId,
          homeId: home.homeId,
          base64Config: fileBase64,
          upgradeConfig: performUpgrade,
        }).unwrap();

        //Refresh the UI
        updateHomeCallback(updatedHome);
        setDialogState(DialogState.Complete);
      } catch (error) {
        console.log('error', error);
        setDialogState(DialogState.Error);
        setError((error as any)?.message);
      }
    };

    uploadConfig();
  }, [home, dialogState, selectedFile, performUpgrade, updateHomeCallback, uploadConfiguration]);

  //Reset the states when dialog closed
  useEffect(() => {
    if (!open) {
      setDialogState(DialogState.Init);
      setSelectedFile(undefined);
      setError(undefined);
      setPerformUpgrade(true);
    }
  }, [open]);

  //Occurs once file has uploaded
  const handleFileChange = async (event: { target: HTMLInputElement }) => {
    if (!event.target.files || event.target.files.length == 0) return;

    //Ensure the file type is .xlsx as the dialog allows for *.* which can't be removed.
    const filename = event.target.files[0].name.toLowerCase();
    if (filename.indexOf(acceptedFileExt) == -1) {
      setDialogState(DialogState.Error);
      setError('Uploaded file must be an Excel file (*.xlsx)');
      return;
    }

    setDialogState(DialogState.Ready);
    setSelectedFile(event.target.files[0]);
  };

  /**
   * Occurs on "Browse" button click.
   * Needed to reset the current file on input so that it can be re-selected and uploaded if an error occurred previously.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileClick = async (event: any) => {
    event.target.value = '';
  };

  //Occurs when perform upgrade checkbox changed
  const handlePerformUpgradeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerformUpgrade(event.target.checked);
  };

  //Occurs when "Upload" is clicked
  const handleUploadClick = async () => {
    setDialogState(DialogState.Uploading);
  };

  //Convert file to base64
  const fileToBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;

        /**
         * Read this for why we split() == https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
         * The blob's result cannot be directly decoded as Base64 without first removing
         * the Data-URL declaration preceding the Base64-encoded data. To retrieve only the Base64 encoded
         * string, first remove data:'*\*;base64, from the result.
         */
        const onlyBase64Data = base64.split(',')[1];
        resolve(onlyBase64Data);
      };

      reader.readAsDataURL(file);
      reader.onerror = reject;
    });

  const inputControlsDisabledStates: DialogState[] = [DialogState.Complete, DialogState.Uploading];
  const closeButtonDisabledStates: DialogState[] = [DialogState.Uploading];
  const uploadButtonDisabledStates: DialogState[] = [
    DialogState.Complete,
    DialogState.Uploading,
    DialogState.Init,
  ];

  return (
    <Dialog open={open}>
      <DialogTitle className={dialogTitleStyle}>Upload Home Configuration</DialogTitle>
      <DialogContent className={dialogContentStyle}>
        Choose the configuration file to upload for <strong>{home.name}</strong>
        <input
          className={twCls(margin('mt-4'))}
          disabled={inputControlsDisabledStates.includes(dialogState)}
          type="file"
          name="uploadConfigFile"
          title="Choose the configuration file to upload."
          accept={acceptedFileExt}
          onChange={handleFileChange}
          onClick={handleFileClick}
        />
        <div className={twCls(margin('mt-2'))}>
          <Checkbox
            disabled={inputControlsDisabledStates.includes(dialogState)}
            checked={performUpgrade}
            onChange={handlePerformUpgradeChange}
          />
          Perform upgrade on previous configuration
        </div>
        {dialogState == DialogState.Error && error && (
          <Alert severity="error" className={twCls(margin('mt-3'))}>
            <span dangerouslySetInnerHTML={{ __html: error }} />
          </Alert>
        )}
        {dialogState == DialogState.Complete && (
          <Alert severity="success" className={twCls(margin('mt-3'))}>
            Configuration was uploaded successfully.
          </Alert>
        )}
      </DialogContent>
      <DialogActions className={twCls(justifyContent('justify-between'))}>
        <div>{dialogState == DialogState.Uploading && <CircularProgress />}</div>
        <div>
          <Button
            disabled={uploadButtonDisabledStates.includes(dialogState)}
            onClick={handleUploadClick}
          >
            Upload
          </Button>
          <Button disabled={closeButtonDisabledStates.includes(dialogState)} onClick={onClose}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
