import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { authFetchBaseQuery, baseManagementUrl } from '../apiConfig';
import { DataCategory, Document, FileCategory } from 'models';

export interface GetDocumentRequest {
  unitId: string;
}

export interface DocumentsResponse {
  documents: Document[];
}

export interface DocumentsData {
  all: Document[];
  grouped: Record<string, Document[]>;
}

export interface HomeConsentRequest {
  unitId: string;
}

export interface HomeConsent {
  consented: boolean;
  userId?: string;
  updatedAt: string;
}

export interface SetHomeConsentRequest {
  unitId: string;
  consented: boolean;
}

export interface GetUploadDocumentUrlRequest {
  unitId: string;
  dataCategory: DataCategory;
  fileCategory: FileCategory | string;
  fileName: string;
  documentName: string;
}

export interface GetUploadDocumentUrlResponse {
  url: string;
  fields: Record<string, string>;
}

export const unitsManagementApi = createApi({
  reducerPath: 'unitsManagementApi',
  baseQuery: authFetchBaseQuery({
    baseUrl: `${baseManagementUrl}/units`,
  }),
  endpoints: (builder) => ({
    getDocuments: builder.query<DocumentsData, GetDocumentRequest>({
      query: ({ unitId }) => ({ url: `${unitId}/documents` }),
      transformResponse: (response: DocumentsResponse) => ({
        all: response.documents,
        grouped: response.documents.reduce(
          (acc, doc) => ({ ...acc, [doc.type]: [...(acc[doc.type] || []), doc] }),
          {} as Record<string, Document[]>,
        ),
      }),
    }),
    getUploadUrl: builder.mutation<GetUploadDocumentUrlResponse, GetUploadDocumentUrlRequest>({
      query: ({ unitId, dataCategory, fileCategory, fileName, documentName }) => ({
        url: `${unitId}/documents/${dataCategory}/${fileCategory}`,
        method: 'POST',
        body: {
          fileName,
          documentName,
        },
      }),
    }),
    getConsent: builder.query<HomeConsent, HomeConsentRequest>({
      query: ({ unitId }) => ({ url: `${unitId}/consent` }),
    }),
    saveConsent: builder.mutation<HomeConsent, SetHomeConsentRequest>({
      query: ({ unitId, consented }) => ({
        url: `${unitId}/consent`,
        method: 'PUT',
        body: { consented },
      }),
    }),
  }),
});

export const uploadDocument = (signedPostUrl: GetUploadDocumentUrlResponse, file: File) => {
  const formData = new FormData();
  Object.entries(signedPostUrl.fields).forEach((entry) => formData.append(entry[0], entry[1]));
  formData.append('file', file);

  return fetch(signedPostUrl.url, { method: 'POST', body: formData });
};

export const {
  useGetDocumentsQuery,
  useLazyGetDocumentsQuery,
  useGetConsentQuery,
  useLazyGetConsentQuery,
  useSaveConsentMutation,
  useGetUploadUrlMutation,
} = unitsManagementApi;
