import { NavPage } from 'layouts/NavPage';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import {
  combineCls,
  display,
  flexDirection,
  fontSize,
  fontWeight,
  padding,
  textColor,
  twCls,
} from 'style';

const navRootLinkClassName = twCls(padding('pt-3'));
const activeRootLinkClassName = combineCls(navRootLinkClassName, twCls(fontWeight('font-black')));
const setActiveRootLinkClassName = ({ isActive }: { isActive: boolean }) =>
  isActive ? activeRootLinkClassName : navRootLinkClassName;

const navChildLinkClassName = twCls(padding('pl-3'));
const activeChildLinkClassName = combineCls(navChildLinkClassName, twCls(fontWeight('font-black')));
const setActiveChildLinkClassName = ({ isActive }: { isActive: boolean }) =>
  isActive ? activeChildLinkClassName : navChildLinkClassName;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SidebarProps {
  appName: string;
  navPages: NavPage[];
}

export const Sidebar: FC<SidebarProps> = ({ appName, navPages }) => {
  return (
    <div className={twCls(display('flex'), flexDirection('flex-col'), padding('px-4'))}>
      <div
        className={twCls(padding('pt-11', 'pb-6'), fontSize('text-3xl'), fontWeight('font-light'))}
      >
        {appName}
      </div>
      <nav
        className={twCls(
          display('flex'),
          flexDirection('flex-col'),
          textColor('text-blue-100'),
          fontSize('text-lg'),
          fontWeight('font-medium'),
        )}
      >
        {navPages.map(({ key, path, pageDisplayName, isRoot }) => (
          <NavLink
            key={key}
            to={path}
            className={isRoot ? setActiveRootLinkClassName : setActiveChildLinkClassName}
            end={true}
          >
            {pageDisplayName}
          </NavLink>
        ))}
      </nav>
    </div>
  );
};
