import { fontSize, fontWeight, margin, textAlign, twCls } from 'style';

export const UnauthorizedPage = (): JSX.Element => {
  return (
    <h1
      className={twCls(
        fontSize('text-3xl'),
        fontWeight('font-light'),
        textAlign('text-center'),
        margin('mt-4'),
      )}
    >
      You are not authorized to view this page.
    </h1>
  );
};
