import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { authFetchBaseQuery, baseManagementUrl } from '../apiConfig';

export type DownloadFileDetails = {
  url: string;
  modificationDate: Date;
  version: string;
};

export const imagesManagementApi = createApi({
  reducerPath: 'imagesManagementApi',
  baseQuery: authFetchBaseQuery({
    baseUrl: `${baseManagementUrl}`,
  }),
  endpoints: (builder) => ({
    getImage: builder.query<DownloadFileDetails, void>({
      query: () => ({ url: 'images/v1?redirect=false' }),
    }),
    getFirmware: builder.query<DownloadFileDetails, void>({
      query: () => ({ url: 'firmware/switches/ultrastat?redirect=false' }),
    }),
  }),
});

export const {
  useGetImageQuery,
  useLazyGetImageQuery,
  useGetFirmwareQuery,
  useLazyGetFirmwareQuery,
} = imagesManagementApi;
