import { PropsWithChildren } from 'react';
import { combineCls, fontSize, fontWeight, lineHeight, twCls } from 'style';

export type PageTitleProps = {
  className?: string;
} & PropsWithChildren;

export const PageTitle = ({ className, children }: PageTitleProps): JSX.Element => {
  return (
    <h1
      className={combineCls(
        twCls(
          fontSize('text-2xl', 'md:text-4xl', 'lg:text-6xl'),
          fontWeight('font-medium'),
          lineHeight('leading-tight'),
        ),
        className,
      )}
    >
      {children}
    </h1>
  );
};
