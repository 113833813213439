import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { Development, Home, HomeImageConfig, HomeState, HomeUser } from 'models';
import { authFetchBaseQuery, baseManagementUrl, countryCode } from 'services/apiConfig';

interface GetHomeRequest {
  developmentId: string;
  homeId: string;
}

interface GetDevelopmentRequest {
  developmentId: string;
}

interface GetHomesForDevelopmentRequest {
  developmentId: string;
}

interface UploadConfigurationRequest {
  developmentId: string;
  homeId: string;
  base64Config: string;
  upgradeConfig: boolean;
}

interface UpdateShadowRequest {
  developmentId: string;
  homeId: string;
}

interface GetConfigImageRequest {
  developmentId: string;
  homeId: string;
}

interface GetHomeStatesRequest {
  developmentId: string;
}

interface InviteUserRequest {
  developmentId: string;
  homeId: string;
  email: string;
  resendInvite: boolean;
}

interface GetUsersRequest {
  developmentId: string;
  homeId: string;
}

export type HomeUserAction = 'Enable' | 'Disable' | 'Delete';

interface UpdateUserRequest {
  developmentId: string;
  homeId: string;
  email: string;
  action: HomeUserAction;
  id?: string;
}

export const developmentsApi = createApi({
  reducerPath: 'developmentsApi',
  baseQuery: authFetchBaseQuery({
    baseUrl: `${baseManagementUrl}/developments`,
  }),
  endpoints: (builder) => ({
    getHome: builder.query<Home, GetHomeRequest>({
      query: ({ developmentId, homeId }) => ({ url: `${developmentId}/homes/${homeId}` }),
    }),
    getHomesForDevelopment: builder.query<Home[], GetHomesForDevelopmentRequest>({
      query: ({ developmentId }) => ({ url: `${developmentId}/homes` }),
    }),
    getDevelopment: builder.query<Development, GetDevelopmentRequest>({
      query: ({ developmentId }) => ({ url: developmentId }),
    }),
    getAllDevelopments: builder.query<Development[], void>({
      query: () => ({ url: `?country=${countryCode}` }),
    }),
    uploadConfiguration: builder.mutation<Home, UploadConfigurationRequest>({
      query: ({ developmentId, homeId, base64Config, upgradeConfig }) => ({
        url: `${developmentId}/homes/${homeId}/configuration`,
        method: 'POST',
        body: { type: 'spreadsheet', format: 'xlsx', data: base64Config, isUpgrade: upgradeConfig },
      }),
    }),
    updateShadow: builder.mutation<Home, UpdateShadowRequest>({
      query: ({ developmentId, homeId }) => ({
        url: `${developmentId}/homes/${homeId}/shadows`,
        method: 'POST',
      }),
    }),
    getConfigImage: builder.query<HomeImageConfig, GetConfigImageRequest>({
      query: ({ developmentId, homeId }) => ({
        url: `${developmentId}/homes/${homeId}/images/config/v1`,
      }),
    }),
    getHomeStates: builder.query<HomeState[], GetHomeStatesRequest>({
      query: ({ developmentId }) => ({
        url: `${developmentId}/homes/states`,
      }),
    }),
    inviteUser: builder.mutation<HomeUser, InviteUserRequest>({
      query: ({ developmentId, homeId, email, resendInvite }) => ({
        url: `${developmentId}/homes/${homeId}/users/invite`,
        method: 'POST',
        body: { email, resendInvite },
      }),
    }),
    getUsers: builder.query<HomeUser[], GetUsersRequest>({
      query: ({ developmentId, homeId }) => ({
        url: `${developmentId}/homes/${homeId}/users`,
      }),
    }),
    updateUser: builder.mutation<HomeUser, UpdateUserRequest>({
      query: ({ developmentId, homeId, email, action, id }) => ({
        url: `${developmentId}/homes/${homeId}/users`,
        method: 'POST',
        body: { email, id, action },
      }),
    }),
  }),
});

export const {
  useGetHomeQuery,
  useLazyGetHomeQuery,
  useGetDevelopmentQuery,
  useLazyGetDevelopmentQuery,
  useGetAllDevelopmentsQuery,
  useLazyGetAllDevelopmentsQuery,
  useUploadConfigurationMutation,
  useUpdateShadowMutation,
  useGetConfigImageQuery,
  useLazyGetConfigImageQuery,
  useGetHomeStatesQuery,
  useLazyGetHomeStatesQuery,
  useGetHomesForDevelopmentQuery,
  useLazyGetHomesForDevelopmentQuery,
  useInviteUserMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useUpdateUserMutation,
} = developmentsApi;
