import { Grid } from '@mui/material';
import { AccordionItem } from 'components/AccordionItem';
import { FileItem } from 'components/FileItem';
import { Document } from 'models/document';
import { margin, twCls } from 'style';

export interface DocumentPanelProps {
  summary: string;
  documents: Document[];
}

export const DocumentPanel = ({ summary, documents }: DocumentPanelProps): JSX.Element => {
  return (
    <AccordionItem summary={summary} className={twCls(margin('mt-4'))}>
      <Grid container columnSpacing={10} rowSpacing={4}>
        {documents?.map((doc) => (
          <Grid key={doc.documentId} item>
            <FileItem
              file={doc.downloadUrl}
              title={doc.documentName}
              lastModified={doc.lastModified}
            />
          </Grid>
        ))}
      </Grid>
    </AccordionItem>
  );
};
