import { borderColor, borderRadius, borderWidth, fontSize, fontWeight, margin, padding, twCls } from 'style';
import { useDeletePortfolioMutation, useGetPortfoliosQuery } from 'services/portfolioApi';
import { Link } from 'react-router-dom';
import { Spinner } from '@mgh-app/component-library';
import { FC } from 'react';

  export interface PortfolioTileProps {
  id: string;
  name: string;
  description: string;
  accountType: string;
}

const PortfolioTile: FC<PortfolioTileProps> = ({
  id,
  name,
  description,
  accountType,
}: PortfolioTileProps): JSX.Element => {
  const [deletePortfolio] = useDeletePortfolioMutation();
  const { refetch } = useGetPortfoliosQuery();
  const DeletePortfolio = async (portfolioId: string) => {
    await deletePortfolio({ id: portfolioId });
    refetch();
  };

  return (
    <div
      className={twCls(
        padding('p-5'),
        margin('mt-4'),
        borderWidth('border'),
        borderRadius('rounded-lg'),
        borderColor('border-grey-400'),
      )}
    >
      <div className={twCls(fontSize('text-3xl', 'lg:text-4xl'), fontWeight('font-light'))}>
        {name}
      </div>
      <div>
        {description}
      </div>
      <div>
        {accountType}
      </div>
      <div>
        <Link to={`/portfolio/${id}`}>
          Edit
        </Link>
      </div>
      <div>
        <Link to={'#'} onClick={() => DeletePortfolio(id)}>
          Delete
        </Link>
      </div>
    </div>
  );
};

export const PortfoliosPage = (): JSX.Element => {
  const { data: portfolios, isLoading } = useGetPortfoliosQuery();

  return isLoading ? (
    <Spinner />
  ) : (
    <div>
      {portfolios
        ?.map((portfolio) =>
          <PortfolioTile
            key={portfolio.id}
            id={portfolio.id}
            name={portfolio.name}
            accountType={portfolio.account_type}
            description={portfolio.description}
          />,
        )}
    </div>
  );
};
