import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import {
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { HomeHeader, OnUploadParams, UploadFileDialog } from 'components';
import { Document } from 'models';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useGetHomeQuery } from 'services/homeManagementApi/developmentsApi';
import {
  uploadDocument,
  useGetUploadUrlMutation,
  useLazyGetDocumentsQuery,
} from 'services/homeManagementApi/unitsManagementApi';
import {
  borderColor,
  borderRadius,
  borderWidth,
  display,
  flexDirection,
  fontSize,
  fontWeight,
  gap,
  gridAutoRows,
  gridTemplateColumns,
  justifyContent,
  margin,
  padding,
  textColor,
  twCls,
} from 'style';

export interface DashboardItemProps {
  title: string;
  moreInformationLink?: string;
  children?: ReactElement;
}

export const DashboardItem = ({
  title,
  moreInformationLink,
  children,
}: DashboardItemProps): JSX.Element => {
  return (
    <div
      className={twCls(
        padding('p-5'),
        margin('mt-4'),
        borderWidth('border'),
        borderRadius('rounded-lg'),
        borderColor('border-grey-400'),
      )}
    >
      <div className={twCls(fontSize('text-3xl', 'lg:text-4xl'), fontWeight('font-light'))}>
        {title}
      </div>
      {children}
      <div
        className={twCls(
          fontWeight('font-medium'),
          textColor('text-blue-100'),
          display('flex'),
          justifyContent('justify-end'),
          margin('mt-8', 'lg:mt-0'),
        )}
      >
        {moreInformationLink && <Link to={moreInformationLink}>More information</Link>}
      </div>
    </div>
  );
};

export interface HomeDetailsPageProps {
  homeId?: string;
}

export const HomeDetailsPage = ({}: HomeDetailsPageProps) => {
  const { developmentId = '', homeId = '' } = useParams();
  const [documents, setDocuments] = useState<Document[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDocsLoader, setShowDocsLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { data: home } = useGetHomeQuery({ developmentId, homeId });
  const [fetchDocuments] = useLazyGetDocumentsQuery();
  const [getUploadUrl] = useGetUploadUrlMutation();
  const closeModal = () => {
    setShowModal(false);
    setErrorMessage('');
  };

  const loadDocuments = useCallback(async () => {
    if (home?.uuid) {
      try {
        setShowDocsLoader(true);
        const { all: data } = await fetchDocuments({ unitId: home?.uuid }).unwrap();
        setDocuments(data);
      } finally {
        setShowDocsLoader(false);
      }
    }
  }, [fetchDocuments, home?.uuid]);

  const upload = async ({ dataCategory, fileCategory, file, documentName }: OnUploadParams) => {
    if (home?.uuid && dataCategory && fileCategory && file) {
      try {
        const uploadUrl = await getUploadUrl({
          unitId: home.uuid,
          dataCategory,
          fileCategory,
          fileName: file.name,
          documentName,
        }).unwrap();

        await uploadDocument(uploadUrl, file);

        closeModal();
        loadDocuments();
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setErrorMessage((err as any).data);
      }
    }
  };

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  return home ? (
    <div
      className={twCls(
        margin('m-8'),
        display('grid'),
        gridTemplateColumns('grid-cols-1'),
        gridAutoRows('auto-rows-min'),
        gap('gap-2'),
      )}
    >
      <HomeHeader
        title={home.name}
        subTitle1={`${home.address.street}, ${home.address.city}`}
        subTitle2={home.address.code}
      />
      <DashboardItem title="Documents">
        <div
          className={twCls(
            margin('mt-4'),
            display('grid'),
            gridTemplateColumns('grid-cols-1'),
            gap('gap-8'),
          )}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="Documents table">
              <TableHead>
                <TableRow>
                  <TableCell>Document Name</TableCell>
                  <TableCell align="right">File Category</TableCell>
                  <TableCell align="right">Data Category</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {showDocsLoader &&
                  [1, 2, 3].map((row) => (
                    <TableRow key={row}>
                      <TableCell component="th" scope="row">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  ))}
                {!showDocsLoader &&
                  documents?.map((row) => (
                    <TableRow
                      key={row.documentId}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={twCls(display('flex'), gap('gap-2'))}
                      >
                        <TextSnippetOutlinedIcon />
                        <div>
                          <a
                            href={row.downloadUrl}
                            download={row.fileName}
                            className={twCls(display('flex'), flexDirection('flex-col'))}
                          >
                            <div className={twCls(fontWeight('font-bold'))}>{row.documentName}</div>
                            <div>{row.fileName}</div>
                            <div>{row.lastModified}</div>
                          </a>
                        </div>
                      </TableCell>
                      <TableCell align="right">{row.type}</TableCell>
                      <TableCell align="right">{row.dataCategory}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <Button onClick={() => setShowModal(true)}>Upload File</Button>
          </div>
        </div>
      </DashboardItem>
      {showModal && (
        <UploadFileDialog
          errorMessage={errorMessage}
          showModal={showModal}
          onClose={closeModal}
          onUpload={upload}
        />
      )}
    </div>
  ) : null;
};
