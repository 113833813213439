import {
  downloadUrlConfigTemplate,
  downloadUrlBootstrapUltra,
  downloadUrlPrepareHome,
} from 'services/apiConfig';
import { DownloadListItem } from 'components/DownloadListItem';
import { display, gap, gridTemplateColumns, gridTemplateRows, twCls } from 'style';
import { PageTitle } from 'components';
import {
  useGetFirmwareQuery,
  useGetImageQuery,
} from 'services/homeManagementApi/imagesManagementApi';

export const DownloadsPage = () => {
  const { data: isoImage } = useGetImageQuery();
  const { data: d1Firmware } = useGetFirmwareQuery();

  return (
    <div
      className={twCls(
        display('grid'),
        gridTemplateColumns('grid-cols-1'),
        gridTemplateRows('grid-rows-min-fc'),
        gap('gap-12'),
      )}
    >
      <PageTitle>Downloads</PageTitle>
      {isoImage && (
        <DownloadListItem
          url={isoImage.url}
          name="Base PC Image"
          description="The image that needs to be installed on the home PC."
          note="This image needs to be accompanied by a config.json which can be downloaded for each home
            in the app."
          updateDate={isoImage.modificationDate}
          version={isoImage.version}
        />
      )}

      {d1Firmware && (
        <DownloadListItem
          url={d1Firmware.url}
          name="D1 UltraStat Firmware"
          description="The latest firmware to be installed on a MGH D1 UltraStat Controller."
          updateDate={d1Firmware.modificationDate}
          version={d1Firmware.version}
        />
      )}

      {downloadUrlConfigTemplate && (
        <DownloadListItem
          url={downloadUrlConfigTemplate}
          name="Home Configuration Template"
          description="The latest configuration template in Excel format."
        />
      )}

      {downloadUrlBootstrapUltra && (
        <DownloadListItem
          url={downloadUrlBootstrapUltra}
          name="Bootstrap the ULTRA Core Home Server Software on a new Distribution Board"
          description="This guide is used when commissioning a new distribution board product either at the factory or onsite if it’s already been installed in a home."
        />
      )}

      {downloadUrlPrepareHome && (
        <DownloadListItem
          url={downloadUrlPrepareHome}
          name="Prepare and configure a Home for Installation of ULTRA"
          description="Use this guide when setting up a home for the first time on the MGH Admin Portal and for later changes to that configuration."
        />
      )}
    </div>
  );
};
