import { NavPage } from 'layouts/NavPage';
import { RouteObject } from 'react-router';
import { PortfoliosPage } from 'pages/PortfolioPortal/PortfoliosPage';
import { AddEditPortfolioPage } from './pages/PortfolioPortal/AddPortfolioPage';

export const portfolioManagerUrl = '/portfolio';

export const portfolioManagerRoutes: RouteObject[] = [
  {
    path: '',
    element: <PortfoliosPage />,
  },
  {
    path: 'portfolio',
    element: <PortfoliosPage />,
  },
  {
    path: 'add',
    element: <AddEditPortfolioPage />,
  },
  {
    path: ':portfolioId',
    element: <AddEditPortfolioPage />,
  }
];

export const portfolioManagerNavPages: NavPage[] = [
  {
    key: 'portfolio-manager',
    path: '',
    pageDisplayName: 'Portfolio Manager',
    isRoot: true,
  },
  {
    key: 'add',
    path: 'add',
    pageDisplayName: 'Add Portfolio',
    isRoot: false,
  },
  {
    key: 'power-bi',
    path: 'powerbi',
    pageDisplayName: 'Power BI',
    isRoot: true,
  },
];
