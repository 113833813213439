import { adminUrl } from 'adminPortalRoutes';
import { useNavigate } from 'react-router';
import { residentUrl } from 'residentPortalRoutes';

export interface UseAppNavigationHook {
  navigateDashboard: () => void;
  navigateHome: () => void;
  navigateHomes: (developmentId: string) => void;
  navigateHomeDetails: (developmentId: string, homeId: string) => void;
}

export const useAppNavigation = (): UseAppNavigationHook => {
  const navigate = useNavigate();

  return {
    navigateDashboard: () => navigate(`${residentUrl}/dashboard`),
    navigateHome: () => navigate('/'),
    navigateHomes: (developmentId: string) =>
      navigate(`${adminUrl}/developments/${developmentId}/homes`),
    navigateHomeDetails: (developmentId: string, homeId: string) =>
      navigate(`${adminUrl}/developments/${developmentId}/homes/${homeId}`),
  };
};
